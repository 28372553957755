import React, { useState, useEffect, useMemo } from "react";
import loadable from "@loadable/component";
const Parser = loadable(() => import("../parser.js"));
import "../../styles/blocks/accordion.scss";

const AccordionItem = (props) => {
  return (<>
    <details
      className={ props.attributes.className }
      anchor={ props.anchor }
      open={ props.isOpen }
      onToggle={ (e) => {
        if (e.target.hasAttribute("open")) {
          props.toggle();
        }
      }}
    >
      <summary>{ props.attributes.title }</summary>
      <Parser content={ props.innerBlocks.map(e => e.innerHTML).join("") || "" } />
    </details>
  </>);
};

const Accordion = (props) => {
  const { isFaq, isMulti } = props.attributes;
  const [isOpen, setOpen] = useState([]);

  useEffect(() => {
    let defaultOpen = [];
    props.innerBlocks.forEach((e,i) => {
      if (e.defaultOpen) {
        defaultOpen.push(i);
      }
    });
    setOpen(defaultOpen);
  }, []);

  const onToggle = (i) => {
    let openItems = [...isOpen].filter(e => e !== i);
    if (isMulti) {
      openItems.push(i);
    } else {
      openItems = [i];
    }
    setOpen(openItems);
  };

  const getScript = useMemo(() => {
    if (!isFaq) {
      return (<></>);
    }
    const jsonLD = {
      "@context": "http://schema.org/",
      "@type": "FAQPage",
      "mainEntity": []
    };
    jsonLD.mainEntity = props.innerBlocks.map(e => {
      return {
        "@type": "Question",
        "name": e.title,
        "acceptedAnswer": {
          "@type": "Answer",
          // "text": e.content.replace(/(<([^>]+)>)/ig, "")
          "text": e.innerBlocks.map(block => block.innerHTML.replace(/<([^>]+)>/ig, "")).join(" ")
        }
      };
    });
    return <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(jsonLD),
      }}
    />
  });
  return (<>
    {
      props.innerBlocks.map((e,i) => {
        return (<AccordionItem
          {...e}
          style={ (i === 0) ? props.style : {} }
          key={i}
          isOpen={ isOpen.includes(i) }
          toggle={ () => { onToggle(i); } }
        />);
      })
    }
    { getScript }
  </>);
};

export default Accordion;
